:root
{
  --yellow: #F5C32C;
    --orange : #FCA61F;
    --red : #d1362e;
    --black : #242D49;
    --gray : #525662;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App{
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black);    
}
/* Button */
.raise {
  color: #d1362e;
  border: 4px solid;
  font: inherit;
  line-height: 1;
  padding: 0.5em 1em;
  border-radius: 20px;
}
.raise:hover,
.raise:focus {
box-shadow: 0 1em 2em -0.5em var(--hover);
transform: translateY(-0.15em);
}
.raise {
  --color: #d1362e;
  --hover: blue;
}
.raise {
  color: var(--color);
  transition: 0.25s;
}
.raise:hover, 
.raise:focus {
  border-color: var(--hover);
  color: blue;
}
.n-wrapper {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.980);
  z-index: 1000; 
}
.n-left{
  flex:1;
  align-items: center;
  display: flex;
  gap: 2rem;
}
.n-name{
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
}
.n-right{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
/* .n-list{
  flex: 10;
} */
.n-list>ul{
  display: flex;
  gap: 2rem;
  margin-right: 2rem;
  list-style: none;
  cursor: pointer;
}
.n-list>ul>li:hover{
  cursor: pointer;
  color: var(--red);
}
.n-button{
  flex: 2;
}
.intro{
  display: flex;
  height: 69vh;
  margin-top: 6rem;
}
.typed-cursor{
  font-size: 3rem;
}
.ty-text span{
  font-size: 3rem;
}
.i-left{
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}
.i-name{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.i-name>:nth-child(1){
  color: var(--black);
  font-weight: bold;
  font-size: 3rem;
}
.i-name>:nth-child(2){
  color: var(--red);
  font-weight: bold;
}
.i-name>:nth-child(3){
  font-weight: 100;
  color: var(--gray);
  font-size: 20px;
  margin-top: 10px;
}
.i-button{
  width: 6rem;
  height: 2rem;
}
.i-right{
  flex: 1;
  position: relative;
}
.i-right>*{
  position: absolute;
  z-index: 1;
}
.i-right>:nth-child(1){
  transform: scale(1.2);
  left: 18%;
  top: -10%;
}
.floatingdiv{
  display: flex;
  background: white;
  box-shadow: var(--boxShadow);
  border-radius: 17px;
  align-items: center;
  padding: 0px 28px 0px 10px;
  height: 4rem;
}
.floatingdiv>img{
  height: 60px;
  margin-right: 10px;
}
.floatingdiv>span{
  font-size: 16px;
  color: black;
}
.blur{
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(72px);
  z-index: -9;
  top: -18%;
  left: 56%;
}
.services{
  padding: 0 3rem 0 3rem;
  display: flex;
  height: 70vh;
  margin-bottom: 8rem;
  margin-top: 9rem;
  margin-bottom: 13rem;
}
.s-left{
  display: flex;
  flex-direction: column;
  position: relative;
  width: 35%;
}
.s-left>:nth-child(1){
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.s-left>:nth-child(2){
  color: var(--red);
  font-size: 2.5rem;
  font-weight: bold;
}
.s-left>:nth-child(3){
  font-weight: 100;
  color: var(--gray);
  font-size: 20px;
  margin-top: 1rem;
}
.s-button{
  width: 8rem;
  margin-top: 1rem;
}
/* sercices card */
.card{
  width: 10rem;
  height: 13rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.26);
  border: 9px solid var(--red);
  border-radius: 30px;
  padding: 0px 26px 2rem 20px;
}
.card span:nth-of-type(1){
  font-size: 22px;
}
.card span:nth-of-type(2){
  color: var(--gray);
  font-size: 18px;
}
.card>img{
  height: 20%;
  margin-top: 10px;
}
.c-button{
  background: #ffffff;
  box-shadow: 0px 19px 60px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  border: none;
  padding: 10px;
  font-size: 16px;
  color: #5290FD;
}
.s-cards{
  position: relative;
}
.s-cards>*{
  position: absolute;
}
.s-blur1{
  top:13rem;
  left: -18rem;
}
.s-blur2{
  left: 14rem;
  top: 8rem;
}
/* skills section */
.skills{
  padding: 0 3rem 0 3rem;
  display: flex;
  height: 90vh;
  margin-top: 16rem;
}
.sk-right{
  position: relative;
}
.sk-mainCircle{
  left: 9rem;
  position: relative;
  width: 18rem;
  height: 18rem;
  border-radius: 100%;
  box-shadow: var(--smboxShadow);
  top: 2rem;
  background: white;
  z-index: 2;
}
.sk-mainCircle>*{
  position: absolute;
}
.sk-mainCircle>:nth-child(1){
  left: 6rem;
  top: -3rem;
}
.sk-mainCircle>:nth-child(2){
  left: -3rem;
  top: 5rem;
}
.sk-mainCircle>:nth-child(3){
  left: 6rem;
  top: 5rem;
}
.sk-mainCircle>:nth-child(4){
  left: 15rem;
  top: 5rem;
}
.sk-mainCircle>:nth-child(5){
  left: 6rem;
  top: 13rem;
}
.sk-secCircle{
  width: 6rem;
  height: 6rem;
  position: absolute;
  border-radius: 100%;
  border: 5px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  background: white;
  box-shadow: var(--smboxShadow);
}
.sk-secCircle>a>img{
  height: 100px;
}
.sk-backCircle{
  position: absolute;
  height: 13rem;
  width: 13rem;
  border-radius: 100%;
  z-index: 1;
}
.blueCircle{
  background: #1949b8;
  left: 18rem;
  top: 0rem;
}
.yellowCircle{
  background: #F5C32C;
  left: 18rem;
  top: 8rem;
}
/* portfolio section */
.portfolio{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}
.portfolio span:nth-of-type(1){
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}
.portfolio span:nth-of-type(2){
  color: var(--red);
  font-size: 2.5rem;
  font-weight: bold;
}
.portfolio .swiper{
  overflow: visible!important;
}
.portfolio-slider{
  margin-top: 3rem;
  width: 100%;
}
.portfolio-slider .swiper-slide{
  width: 20rem;
}
.portfolio img{
  width: 19rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
}
/* contact section */
.contact-form{
  padding: 0 3rem 0 3rem;
  display: flex;
  height: 80vh;
  margin-top: 4rem;
}
.c-right{
  display: flex;
  justify-content: center;
  position: relative;
  flex: 1;
}
.c-right>form{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
.c-right .user{
  width: 20rem;
  height: 2rem;
  padding: 0.3rem;
  outline: none;
  border: 3px solid var(--red);
  border-radius: 8px;
  font-size: 16px;
}
textarea{
  height: 4rem!important;
}
.c-blur1{
  top: 1rem;
  left: 8rem;
}
/* toogle */
.toggle{
  display: flex;
  justify-content: space-between;
  border: 3px solid var(--red);
  border-radius: 1rem;
  position: relative;
  padding: 2px;
  cursor: pointer;
}
.toggle>*{
  width: 1rem;
  height: 1rem;
  color: var(--red);

}
.t-button{
  border-radius: 100%;
  background: var(--red);
  position: absolute;
}
/* socials */
.socials{
  display: flex;
  justify-content: flex-start;
}
.socials ul{
  position: relative;
  display: flex;
  padding: 10px;
}
.socials ul li{
  position: relative;
  list-style: none;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: #fff;
  border: 2px solid var(--red);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25);
  transition: 0.5s;   
}
.socials ul li:hover{
  width: 100px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0);
}
.socials ul li::before{
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50px;
  background: linear-gradient(45deg, var(--i), var(--j));
  opacity: 0;
  transition: 0.5s;
}
.socials ul li:hover::before{
  opacity: 1;
}
.socials ul li::after{
  content: '';
  position: absolute;
  top: 10px;
  width: 100%;
  border-radius: 60px;
  background: linear-gradient(45deg, var(--i), var(--j));
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
  filter: blur(15px);
}
.socials ul li:hover::after{
  opacity: 0.5;
}
.socials ul li i{
  color: #000000;
  font-size: 1.75em;
  transition: 0.5s;
  transition-delay: 0.25s;
}
.socials ul li:hover i {
  transform: scale(0);
  font-size: 1.75em;
  transition-delay: 0.25s;
}
.socials ul li span{
  position: absolute;
}
.socials ul li .titulo a{
  text-decoration: none;
  color: #fff;
}
.socials ul li .titulo{
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform: scale(0);
  transition: 0.5s;
  transition-delay: 0s;
}
.socials ul li:hover .titulo{
  transform: scale(1);
  transition-delay: 0.25s;
}

/* responsive */
@media screen and (max-width: 480px) {
  .App{
    padding: 0.5rem 1rem;
  }
  .n-list{
    display: none;
  }
  .intro{
    flex-direction: column;
    margin-top: 1rem;
    height: 64rem;
  }
  .typing span{
    font-size: 1.5rem;
  }
  .typing span span{
    font-size: 1.5rem;
  }
  .i-right{
    transform: scale(0.6);
    left: -3rem;
  }
  .i-floaters:nth-of-type(1){
    top: -4rem!important;
    left: 20rem!important;
  }
  .i-floaters:nth-of-type(2){
    left: 2rem!important;
  }
  .services{
    margin-top: 0;
    flex-direction: column;
    gap: 5rem;
    height: 66rem;
    padding: 1rem;
  }
  .s-cards{
    display: flex;
    flex-direction: column;
    gap: 17rem;
  }
  .s-cards>*{
    position: static;
  }
  .card{width: auto;}
  .skills{
    flex-direction: column;
    height: 55rem;
    padding: 1rem;
    gap: 5rem;
  }
  .sk-right{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transform: scale(0.7);
  }
  .sk-mainCircle{
    position: static;
  }
  .sk-backCircle{
    left: 10rem!important;
  }
  .contact-form{
    padding: 0;
    flex-direction: column;
    gap: 5rem;
    height: 48rem;
}
 .s-left{
  width: 100%;
}
.c-right .user{
    width: 16rem;
}

}
